import { buildProperty, buildSchema } from "@camberi/firecms";

import type { HomepageContent, Page } from "../../shared";

interface Image {
  title: string;
  image: string;
}

export const imageSchema = buildSchema<Image>({
  name: "Image",
  description:
    "Here, you can upload images to be used within the content of a public or internal page. After selecting and uploading an image, click the arrow below the image to load it in a new tab. You can then copy the URL of the image and paste it into the content of a page.",
  properties: {
    title: buildProperty({
      title: "Title",
      validation: { required: true },
      dataType: "string",
    }),
    image: buildProperty({
      title: "Image",
      description:
        "Ensure the image is a reasonable size for the web before uploading. Services such as https://tinypng.com/ can be used to compress the image.",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images/shared-images",
          acceptedFiles: ["image/*"],
          maxSize: 1.5 * 1024 * 1024, // 1.5MB
          metadata: {
            cacheControl: "max-age=1000000",
          },
        },
      },
    }),
  },
});

export const publicHomepageContentSchema = buildSchema<HomepageContent>({
  name: "Public homepage content",
  properties: {
    mainHeading: buildProperty({
      title: "Main heading",
      validation: { required: true },
      dataType: "string",
    }),
    content: buildProperty({
      title: "Content",
      validation: { required: false },
      dataType: "string",
      config: {
        markdown: true,
      },
    }),
  },
});

export const publicPageSchema = buildSchema<Page>({
  name: "Public page",
  properties: {
    title: buildProperty({
      title: "Title",
      validation: { required: true },
      dataType: "string",
    }),
    headerImage: buildProperty({
      title: "Header image",
      description:
        "Image to be displayed in the header. Wide images are recommended. 1280x480px is ideal, just like the header images used in articles on fortum.com/about-us/newsroom",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images/public",
          acceptedFiles: ["image/*"],
          metadata: {
            cacheControl: "max-age=1000000",
          },
        },
      },
    }),
    content: buildProperty({
      title: "Content",
      description:
        "Use the built-in editor to customise the contents of this page.",
      validation: { required: true },
      dataType: "string",
      config: {
        markdown: true,
      },
    }),
    published: buildProperty({
      title: "Published",
      description:
        "If this page is published, it will be visible on the public website.",
      validation: { required: false },
      dataType: "boolean",
    }),
  },
});

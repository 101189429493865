import { getApps, initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";

import {
  BuildStatus,
  FIREBASE_FUNCTIONS_REGION,
  ISODateString,
} from "../../../shared";

import { firebaseConfig } from "./firebase.config";

const apps = getApps();
const firebaseApp = apps.length ? apps[0] : initializeApp(firebaseConfig);

const functions = getFunctions(firebaseApp, FIREBASE_FUNCTIONS_REGION);

export const getPublicSiteBuildStatus = httpsCallable<
  // Params:
  never,
  // Returns (after promise resolves):
  {
    status: BuildStatus;
    startTime: ISODateString;
    finishTime: ISODateString;
  }
>(functions, "getPublicSiteBuildStatus");

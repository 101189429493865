export const APP_NAME = "Fintraffic CMS";
export const LOGO_PATH = "/logo.png";
export const ADD_AN_IMAGE_PATH = "/add-image.png";
export const COPY_IMAGE_URL_PATH = "/copy-image-url.png";

// See https://design.fintraffic.com/elemental/#/Style/Colors
export const PRIMARY_COLOUR = "#333333";
export const SECONDARY_COLOUR = "#5ac37d";

export const TEN_MINUTES = 1000 * 60 * 10;
